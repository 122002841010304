define('ember-paper/components/paper-menu-content', ['exports', 'ember-paper/components/paper-menu-container'], function (exports, _emberPaperComponentsPaperMenuContainer) {
  /**
   * @module ember-paper
   */
  'use strict';

  /**
   * @class PaperMenuContent
   * @extends PaperMenuContainer
   */
  exports['default'] = _emberPaperComponentsPaperMenuContainer['default'];
});